<template>
  <div class="warp">
    <div class="xzdd">
      <p>选择订单</p>
      <div class="right">
        <div class="item-list" v-for="(item, index) in itemList.orderList" :key="index">
          <p style="font-weight: 700;">{{item.companyName}}({{item.categoryName}})</p>
          <p>订单号：{{item.orderNo}}</p>
          <p>订单时间：{{item.appointmentBeginTime}}-{{item.appointmentEndTime.substring(11)}}</p>
        </div>
      </div>
    </div>
    <van-field
        name="处理方式"
        label-width="80"
        v-if="handleWay"
        :value="findquery(itemList.handleWay, handleWay)"
        label="处理方式"
      />
    <van-field
        name="称重重量"
        label-width="80"
        :value="itemList.signVolume ? itemList.signVolume + 'T' : ''"
        label="称重重量"
      />
    <van-field
        name="接收单位"
        label-width="80"
        :value="itemList.receiveComName"
        label="接收单位"
      />
    <van-field name="上传过磅单" label="上传过磅单" label-width="80">
      <template #input>
        <van-uploader
          :deletable="false" 
          :show-upload="false"
          v-model="uploadPaths" />
      </template>
    </van-field>
    <van-field 
        :value="itemList.remarks"
        rows="2"
        autosize
        label="备注"
        type="textarea"
        maxlength="50"
        show-word-limit />
    <div class="btn">
      <van-button round block type="info" @click="gobackClick">返回</van-button>
    </div>
  </div>
</template>

<script>
import { orderGoid } from '@/api/qyyorder.js'
import { dictType } from '@/api/notice.js'
export default {
  data () {
    return {
      itemList: [],
      uploadPaths: [],
      client: new OSS.Wrapper({
        region: 'oss-cn-hangzhou',
        accessKeyId: 'LTAI5tRypK9PNyfDKPcabDhD',
        accessKeySecret: 'ykKGkcSM0ewsUcW4rAUd9htNOJwKVp',
        bucket: 'hzjiaduomei-huishou'
      }),
      handleWay: undefined, // 处置方式
    }
  },
  created() {
    this.dictType()
  },
  methods: {
    findquery(code, arr) {
      return arr.find(item => item.dictValue == code).dictLabel
    },
    dictType() { // 字典
      dictType('handle_way').then(res => { // 单位类型
        if (res.data.code === 200) {
          this.handleWay = res.data.data
          this.orderGoid()
        }
      })
    },
    orderGoid() { // 订单详情
      orderGoid(this.$route.query.id).then(res => {
        if (res.data.code === 200) {
          this.itemList = res.data.data
          const paths = this.itemList.uploadPaths.split(',')
          paths.forEach(item => {
            this.uploadPaths.push({url: this.client.signatureUrl(item)})
          })
        }
      })
    },
    gobackClick() {
      this.$router.go(-1)
    }
  }
}

</script>
<style lang="scss" scoped>
.warp {
  padding: 10px 15px;
  background-color: #fff;
  font-size: 14px;
  .xzdd {
    display: flex;
    p {
      width: 80px;
    }
    .right {
      flex: 1;
      .item-list {
        width: 100%;
        margin-bottom: 10px;
        border-bottom: 1px solid #ddd;
        p {
          width: 100%;
          line-height: 20px;
        }
      }
    }
  }
}
::v-deep .van-button--round {
  border-radius: 3px;
}
::v-deep .van-button--info {
  background-color: #0087f0;
  border: 1px solid #0087f0;
}
::v-deep .van-cell {
  padding: 10px 0;
}
::v-deep .van-field__label {
  margin-right: 0;
}
</style>
